import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Structure3DLogo from './img/Structure3D-1.svg';
import ConcreteSectionLogo from './img/ConcreteSection.svg';
import SteelSectionLogo from './img/SteelSection.svg';
// import ReactBodymovin from 'react-bodymovin';
// import animation from './img/animation.json';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
  } from 'reactstrap';
import InterWaveSvg from './img/InterWave.svg';
import AskForDemoButton from '../../../../components/Button/AskForDemo';


const items = [
  {
    src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: 'Slide 2',
    caption: 'Slide 2'
  },
  {
    src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
    altText: 'Slide 3',
    caption: 'Slide 3'
  }
];


export default class AppSection extends Component{
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render(){
    const { activeIndex } = this.state;
    // const bodymovinOptions = {
    //   loop: true,
    //   autoplay: true,
    //   prerender: true,
    //   animationData: animation
    // }
    const { link } = this.props;
    console.log(link);
    return(
      <div className="appSection">  
        <Container className="appContainer">
          <Row>
            <Col md={4}>
            <FormattedMessage id="structure3D_alt">
              {
                alt =>  (
                  <a
                    title="Structure 3D"
                    href={`${link}/structure3d`}
                    target="_blank"
                  >
                    <img
                      className="img" 
                      src={Structure3DLogo}
                      alt={alt} 
                    />
                  </a>
                )
              }
            </FormattedMessage>
              {/* <ReactBodymovin options={bodymovinOptions} /> */}
              <h2 className="title">
                <FormattedMessage id="structure3D_title" />
              </h2>
              <p className="lead">
                <FormattedMessage 
                  id="structure3D_text" 
                  values={{
                    strong: <strong>
                      <FormattedMessage id="structure3D_strong" />
                    </strong>
                  }}
                />
              </p>
              <Row>
                <Button
                  className="negativeButton"
                  href={`${link}/dashboard`}
                >
                  <FormattedMessage id="structure3D_title" />
                </Button>
                {/* <AskForDemoButton
                  id="structure3D_title"
                  defaultMessage="Structure 3D"
                  className="negativeButton"
                /> */}
              </Row>
            </Col>
            <Col md={4}>
              <FormattedMessage id="steelSection_alt">
                {
                  alt =>  (
                    <a
                      title="Steel Section"
                      href={`${link}/dashboard`}
                      target="_blank"
                    >
                      <img 
                        className="img"
                        src={SteelSectionLogo}
                        alt={alt}
                      />
                    </a>
                  )
                }
              </FormattedMessage>
              <h2 className="title">
                <FormattedMessage id="steelSection_title" />
              </h2>
              <p className="lead">
                <FormattedMessage 
                  id="steelSection_text" 
                  values={{
                    strong: <strong>
                      <FormattedMessage id="steelSection_strong" />
                    </strong>
                  }}
                />
              </p>
              <Button
                className="negativeButton"
                href={`${link}/dashboard`}
              >
                <FormattedMessage id="steelSection_title" />
              </Button>
              {/* <AskForDemoButton
                id="steelSection_title"
                defaultMessage="Steel Section"
                className="negativeButton"
              /> */}
            </Col>
            <Col md={4}>
              <FormattedMessage id="concreteSection_alt">
                {
                  alt =>  (
                    <a
                      title="Concrete Section"
                      href={`${link}/concretesection`}
                      target="_blank"
                    >
                      <img 
                        className="img"
                        src={ConcreteSectionLogo}
                        alt={alt}
                      />
                    </a>
                  )
                }
              </FormattedMessage>
              <h2 className="title">
                <FormattedMessage id="concreteSection_title" />
              </h2>
              <p className="lead">
                <FormattedMessage 
                  id="concreteSection_text" 
                  values={{
                    strong: <strong>
                      <FormattedMessage id="concreteSection_strong" />
                    </strong>
                  }}
                />
              </p>
              <Button
                className="negativeButton"
                href={`${link}/dashboard`}
              >
                <FormattedMessage id="concreteSection_title" />
              </Button>
              {/* <AskForDemoButton
                id="concreteSection_title"
                defaultMessage="Concrete Section"
                className="negativeButton"
              /> */}
            </Col>
          </Row>
          <h2 className="title">
            <FormattedMessage id="app_coming_soon" defaultMessage="And many more applications are coming..."/>
          </h2>
          <br />
        </Container>
      </div>            
    )
  }
}