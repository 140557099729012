import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import InterWaveSvg from './img/InterWave.svg';
// import WorldSvg from './img/World.svg';
import WorldSvg from './img/undraw_3-d_modeling_h-60-h.svg';
import AskForDemoButton from '../../../../components/Button/AskForDemo';
import LocalizedLink from '../../../../components/LocalizedLink';


export default class NoInstallationSection extends Component {
  render() {
    const { link } = this.props;
    return (
      <div className="section">
        <Container>
          <Row>
            <Col md={6}>
              <Row>
                <h2 className="title">
                  <FormattedMessage
                    id="noinstallation.title"
                    defaultMessage="No installation, no maintenance"
                  />
                </h2>
              </Row>
              <Row>
                <p className="lead">
                  <FormattedMessage
                    id="noinstallation.text.1"
                    values={{
                      strong1: <strong>
                        <FormattedMessage id="noinstallation.text.1.strong.1" />
                      </strong>,
                      strong2: <strong>
                        <FormattedMessage id="noinstallation.text.1.strong.2" />
                      </strong>,
                      strong3: <strong>
                        <FormattedMessage id="noinstallation.text.1.strong.3" />
                      </strong>
                    }}
                  />
                </p>
                <p className="lead">
                  <FormattedMessage id="noinstallation.text.2" />
                </p>
              </Row>
              <Row>
                {/* <Button
                  href={link}
                  className="mainButton"
                >
                  <FormattedMessage
                    id="noinstallation.button"
                    defaultMessage="Start"
                  />
                </Button> */}
                <LocalizedLink to="/contact/">
                  <Button
                    className="mainButton"
                  >
                    {`Demande de démo`}
                  </Button>
                </LocalizedLink>
                {/* <Button
                  href={link}
                  className="mainButton"
                >
                  <FormattedMessage
                    id="noinstallation.button"
                    defaultMessage="Start"
                  />
                </Button> */}
                {/* <AskForDemoButton
                  id="landingPage.presentation.button.demo"
                  defaultMessage="Ask for a demo"
                  className="negativeButton"
                /> */}
              </Row>
            </Col>
            <Col md={6}>
              <img className="img" src={WorldSvg} alt="No Installation, No Maintenance" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
