import React, { Component } from 'react'
import PlaceItImg from './img/PlaceIt.png'
import TriStructure from './img/TriStructure.svg'

export default class MobileSection extends Component{
    render(){
        return(
            <div className="mobilesection content-section-a">
            <div className="container">
                <div className="row slideanim">
                    <div className="col-sm-6">
                        <img className="img-responsive" src={PlaceItImg} alt="" />
                    </div>
                    <div className="col-sm-6">
                        <form className="form-inline">
                            <div className="input-group">
                                <span className="input-group-btn">
                                    <img id="logo-app" className="img-responsive" src={TriStructure} alt="" />
                                </span>
                                    <h2 className="section-heading">TriStructure</h2>
                            </div>
                        </form>
                        <p className="lead">TriStructure est une application mobile permettant de faire:</p>
                        <ul className="lead detail"> 
                            <li>Des calculs de résistance des matériaux</li>
                            <li>Des vérifications de sections en acier aux standards internationaux</li>
                            <li>Des vérifications de sections en béton armé aux standards internationaux</li>
                        </ul>
                        <p className="lead">
                            Bientôt disponible en application mobile Iphone et Android
                        </p>
                        <a href='http://play.google.com/store/apps/details?id=com.triazur.tristructure&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="200" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/></a>                    
                    </div>
                </div>        
            </div>
        </div>
        )
    }
}