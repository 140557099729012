import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
import CalypsoIcon from './img/CalypsoIcon.svg';
import CalypsoScreenshoot from './img/CalypsoScreenshoot.png';

export default class IASection extends Component{
  render(){
    return(
      <div className="iasection content-section-b">
        <Container>
          <Row className='slideanim'>
            <Col sm={6}>
              <InputGroup className="input-group">
                <InputGroupAddon addonType="prepend">
                  <img id="logo-app" className="img-responsive" src={CalypsoIcon} alt="" />
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <h2 className="section-heading">CALYPSO</h2>
                </InputGroupAddon>
              </InputGroup>
              <p className="lead">Calypso est l'intelligence artificiel de TriAzur 
              dont le but est d'assister dans les calculs et la recherche
              d'information.</p>
              <p className="lead">Calypso est reliée aux services suivants:</p>
              
              <ul className="lead detail"> 
                  <li><a href="https://dialogflow.com/">Dialogflow</a> de Google pour la partie chatbot</li>
                  <li><a href="https://www.tensorflow.org/">TensorFlow</a> de Google pour les algorithmes de machine learning  
                      et de réseaux neuronnaux
                  </li>
                  <li><a href="https://www.ibm.com/watson/services/discovery/">Watson Discorvery</a> d'IBM afin de permettre de faire 
                  des recherches dans de la documentation scientifique tels que l'Eurocodes. Avec l'option <a href="https://www.ibm.com/watson/services/discovery-news/">Watson Discorvery News </a>
                  il est également possible de faire des recherches sur l'actualité.</li>
              </ul>
            </Col>
            <Col sm={6}>
              <img className="img-responsive" src={CalypsoScreenshoot} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
