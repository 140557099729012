import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  Nav,
  Row,
  NavItem,
  Button,
  Col,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import LandingPage from './img/Background4.svg';
import MacScreenPng from './img/Mac screen@2x-min1.png';
// import LandingPage from './img/HeaderLandingPage.svg';
// import LandingPage from './img/HeaderBackGround.svg';
// import LandingPage from './img/LandingPageBackGround.svg';
// import LandingPage from './img/HeaderWithWave.svg';
// import MacScreen from './img/MacScreen.svg';
// import IphoneScreen from './img/IphoneScreen.svg';
// import LandingPage from './img/LandingPage.jpg';
import Image1Png from './img/Image1.png';
import Image2Png from './img/Image2.png';
import Image3Png from './img/Image3.png';
import TriAzurIcon from './img/TriAzurIcon.png';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import AskForDemoButton from '../../../../components/Button/AskForDemo';
import LocalizedLink from '../../../../components/LocalizedLink';

export default class Header extends Component {
  render() {
    const { link } = this.props;
    return (
      // <div className="header-home-page" style={{backgroundImage: `url(${LandingPage})`}}>
      <div className="header-home-page">
        {/* <div className="header">   */}
        {/* <img src={LandingPage} className="image-header-background"/> */}
        <Container fluid className="headerBackground">
          <Row>
            <Col lg={4}>
              <div className="intro-message">
                <Row>
                  <Fade left>
                    <h1 id="title">
                      <FormattedMessage
                        id="header.title"
                        defaultMessage="Online structural analysis software"
                      />
                    </h1>
                  </Fade>
                </Row>
                <Row>
                  <hr className="spacing" />
                </Row>
                <Row>
                  <Fade left>
                    <h5 id="subtitle">
                      <FormattedMessage
                        id="header.subtitle.1"
                        defaultMessage="No need to install any software, available everywhere, "
                      />
                      <strong>
                        <FormattedMessage
                          id="header.subtitle.2"
                          defaultMessage="easy and powerful. "
                        />
                      </strong>
                      <FormattedMessage
                        id="header.subtitle.3"
                        defaultMessage="With TriDyme, your work is "
                      />
                      <strong>
                        <FormattedMessage
                          id="header.subtitle.4"
                          defaultMessage="directly in the browser."
                        />
                      </strong>
                    </h5>
                  </Fade>
                </Row>
                <Row>
                  <hr className="spacing" />
                </Row>
                <Row>
                  <Col lg={6}>
                    <Fade left>
                      {/* <Button
                        className="mainButton"
                        href={`${link}/signup`}
                        color="primary"
                        size="lg">
                        <FormattedMessage
                          id="header.button.start"
                          defaultMessage="Try it now"
                        />
                      </Button> */}
                      <LocalizedLink to="/contact/">
                        <Button
                          className="mainButton"
                          color="primary"
                          size="lg"
                        >
                          {`Demande de démo`}
                        </Button>
                      </LocalizedLink>



                      {/* <AskForDemoButton
                        id="landingPage.presentation.button.demo"
                        defaultMessage="Ask for a demo"
                        className="mainButton"
                      /> */}
                    </Fade>
                  </Col>
                  <Col lg={6} />
                </Row>
              </div>
            </Col>
            <Col lg={8}>
              <Row>
                <Col xs={12}>
                  <img src={LandingPage} className="image-header-background" />
                  <Fade left>
                    <img src={Image1Png} alt className="image-header" />
                  </Fade>
                  <Fade left>
                    <img
                      className="image-header left"
                      src={Image3Png}
                    // alt={alt} 
                    />
                  </Fade>
                  <Fade right>
                    <img
                      className="image-header right"
                      src={Image2Png}
                    // alt={alt} 
                    />
                  </Fade>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}