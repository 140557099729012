import React from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import { Link } from 'gatsby';
import LocalizedLink from '../../../../components/LocalizedLink';
import { makeStyles } from '@material-ui/core/styles';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  ListSubheader,
  IconButton,
  Paper,
  Tabs,
  Tab,
  AppBar,
  Typography
} from '@material-ui/core';
import ConstructeursSvg from './img/constructeurs.svg';
import ArchitectesSvg from './img/architectes.svg';
import BureauxEtudesSvg from './img/bureaux-etudes.svg';
import EntreprisesSvg from './img/entreprises.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // width: 500,
    height: 550,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

const Prospects = ({
  link
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="prospectsSection">
      <Container className="prospectsContainer">
        <Row>
          <Col lg={12}>
            <h2 className="title-3 text-dark mb-3">Vous êtes ?</h2>
            <p className="lead">
              Le but de TriDyme est de permettre la transformation technologique du secteur de la Construction.
              Que vous soyez <strong><LocalizedLink to="/contractor/">maitre d'ouvrage</LocalizedLink></strong>, <strong><LocalizedLink to="/architect/">architecte</LocalizedLink></strong>, <strong><LocalizedLink to="/engineer/">maitre d'oeuvre</LocalizedLink></strong> ou encore <strong><LocalizedLink to="/constructionCompany/">entreprise générale</LocalizedLink></strong>, TriDyme propose des <strong>solutions techniques innovantes à haute valeur ajoutée</strong>.
            </p>
          </Col>
          <Col lg={12}>
            <div className={classes.root}>
              <div className="prospects-tabs">
                <AppBar
                  position="static"
                  color="default"
                  className="app-bar"
                >
                  <Tabs
                    className="tabs"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Maitre d'ouvrage" {...a11yProps(0)} />
                    <Tab label="Architectes et Maitre d'oeuvre" {...a11yProps(1)} />
                    <Tab label="Bureaux d'études" {...a11yProps(2)} />
                    <Tab label="Entreprises et Artisans" {...a11yProps(3)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={ConstructeursSvg} />
                      </div>
                      <div className="col-9 lead">
                        <p >
                          TriDyme implémente des Applications Web sur-mesures permettant aux Maitres d'Ouvrages
                          d'avoir un suivi en temps réel des différentes phases de vie d'un projet
                          de Construction.
                        </p>
                        <p>
                          Ces solutions permettent d'optimiser les coûts et de faciliter la communication
                          entre les différents intervenants.
                        </p>
                        {/* <Button
                          className="positiveButton"
                          href={link}
                        >
                          Nos Solutions
												</Button> */}
                        <LocalizedLink to="/contact/">
                          <Button
                            className="positiveButton"
                          >
                            {`Demande de démo`}
                          </Button>
                        </LocalizedLink>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={ArchitectesSvg} />
                      </div>
                      <div className="col-9 lead">
                        <p>
                          TriDyme développe des plugins vous permettant de connecter
                          vos logiciels métiers tels que <a href="https://archicad.fr/" target="_blank" title="Archicad">Archicad</a> où <a href="https://www.autodesk.fr/products/revit/overview?mktvar002=3360535|SEM|645561709078675798633kwd-295427476226&1088704&gclsrc=aw.ds&&mkwid=suWMVx9DQ|pcrid|378595182618|pkw|revit|pmt|e|pdv|c|slid||pgrid|78675798633|ptaid|kwd-295427476226|pid|&utm_medium=cpc&utm_source=google&utm_campaign=GGL_DEC_Revit_EMEA_FR_eComm_SEM_BR_New_MIX_ADSK_3360535_Revit&utm_term=revit&utm_content=suWMVx9DQ|pcrid|378595182618|pkw|revit|pmt|e|pdv|c|slid||pgrid|78675798633|ptaid|kwd-295427476226|&gclid=EAIaIQobChMIzLKW8LuN6AIVB7LtCh0Gdg08EAAYASAAEgKpOPD_BwE&s_kwcid=AL!11172!3!378595182618!e!!g!!revit&ef_id=XkPPpwAAAaFQaBTJ:20200309131139:s" target="_blank" title="Revit">Revit</a> aux autres acteurs de la Construction.
                        </p>
                        <p>
                          Ces solutions permettent d'optimiser les coûts et de faciliter la communication
                          entre les différents intervenants.
                        </p>
                        {/* <Button
                          tag={Link}
                          className="positiveButton"
                          to="/architectes"
                        >
                          Nos Solutions
                        </Button> */}
                        <LocalizedLink to="/contact/">
                          <Button
                            className="positiveButton"
                          >
                            {`Demande de démo`}
                          </Button>
                        </LocalizedLink>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={BureauxEtudesSvg} />
                      </div>
                      <div className="col-9 lead">
                        <p>
                          Les Bureaux d'études techniques ont un rôle de validation et
                          d'optimisation des projets de construction.
                          Ils sont amenés à développer des solutions et rédiger des notes de calculs.
                        </p>
                        <p>
                          TriDyme aide à convertir ces outils type excels ou logiciels en Applications Web.
                          Permettant ainsi aux bureaux d'études de conserver le savoir faire en interne (
                          <a href="https://www.tridyme.com/fr/blog/4-raisons-de-passer-de-excel-aux-web-apps/" target="_blank">4 raisons de passer de l’Excel aux Applications Web</a>)
                          et de capitaliser dessus.
                        </p>
                        <p>
                          Le bureau d'étude passe ainsi de centre de coûts en centre de profits.
                        </p>
                        {/* <Button
                          href={link}
                          className="positiveButton"
                        >
                          Nos Solutions
                        </Button> */}
                        <LocalizedLink to="/contact/">
                          <Button
                            className="positiveButton"
                          >
                            {`Demande de démo`}
                          </Button>
                        </LocalizedLink>
                        {/* <Link className="button button-primary mt-2" to="#solutions">Nos solutions</Link> */}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <img src={EntreprisesSvg} />
                      </div>
                      <div className="col-9 lead">
                        <p>
                          Depuis les relevés de bâtiments et les premières esquisses de conception
                          jusqu'à la réception de chantier et la clôture des comptes,
                          TriDyme peut vous accompagner sur l'ensemble des tâches propres à votre activité
                          en mettant en place des solutions sur-mesure.
                        </p>
                        <p>
                          Ces solutions permettent d'optimiser les coûts et de faciliter la communication
                          entre les différents intervenants.
                        </p>
                        {/* <Button
                          href={link}
                          className="positiveButton"
                        >
                          Voir
                        </Button> */}
                        <LocalizedLink to="/contact/">
                          <Button
                            className="positiveButton"
                          >
                            {`Demande de démo`}
                          </Button>
                        </LocalizedLink>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Prospects;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-panel">{children}</div>}
    </Typography>
  )
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}