import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import WaveSvg from './img/Wave.svg';
import InterWaveSvg from './img/InterWave.svg';
import AskForDemoButton from '../../../../components/Button/AskForDemo';

const {
  REACT_COMPANY_NAME,
} = process.env;

export default class VideoSection extends Component {
  render() {
    const { link } = this.props;
    return (
      <div className="section">
        <Container className="videoContainer">
          <Row>
            <Col md={6}>
              <div className="videoResponsive">
                <iframe title={`${REACT_COMPANY_NAME} Presentation`} width="420" height="345" src="https://www.youtube.com/embed/pbTfBqjuSQA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
              </div>
            </Col>
            <Col md={6}>
              <h2 className="title">
                <FormattedMessage id="video.title" />
              </h2>
              <p className="lead">
                <FormattedMessage
                  id="video.text.1"
                  values={{
                    strong1: <strong>
                      <FormattedMessage id="video.text.1.strong.1" />
                    </strong>,
                    strong2: <strong>
                      <FormattedMessage id="video.text.1.strong.2" />
                    </strong>
                  }}
                />
              </p>
              <p className="lead">
                <FormattedMessage
                  id="video.text.2"
                  values={{
                    strong1: <strong>
                      <FormattedMessage id="video.text.2.strong.1" />
                    </strong>,
                    strong2: <strong>
                      <FormattedMessage id="video.text.2.strong.2" />
                    </strong>
                  }}
                />
              </p>
              <p className="lead">
                <FormattedMessage id="video.text.3" />
              </p>
              <Button
                href={link}
                className="mainButton"
              >
                <FormattedMessage id="start" />
              </Button>
              {/* <AskForDemoButton
                id="landingPage.presentation.button.demo"
                defaultMessage="Ask for a demo"
                className="negativeButton"
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
