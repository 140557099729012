import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import InterWaveSvg from './img/InterWave.svg';
import WaveSvg from './img/Wave.svg';
import AllianceDuBatiment from './img/AllianceDuBatiment.jpeg';
import Spie from './img/Spie.png';
import Orange from './img/Orange.png';
import VBMC from './img/VBMC.png';
import AlpesControles from './img/AlpesControles.jpeg';
import VinciConstruction from './img/VinciConstruction.png';
import DEF from './img/DEF-1.jpeg';
import Socotec from './img/Socotec.png';
import PBM from './img/PBM.png';
import ESITCParisPng from './img/ESITCParis1.png';
import ESITCMetzPng from './img/ESITCMetz.png';
import SolideoJpg from './img/Solideo.jpg';
import datBIMJpg from './img/datBIM.png';
import HDSIJpg from './img/HDSI.jpg';
import ImpulsePartnersPng from './img/ImpulsePartners.png';
import BatisseurDAvenirPng from './img/BatisseurDAvenir.png';
import BuildingSmartPng from './img/buildingSmart.png';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  avatar: {
    boxShadow: "3px 3px 20px rgba(0, 0, 0, .6)",
    height: "100px",
    width: "100px",
    backgroundColor: 'white',
    margin: 'auto'
  },
  img: {
    maxWidth: "90px",
    height: "auto"
  }
}));


const referenceList = [
  {
    href: "https://www.socotec.fr/",
    id: "reference.esitc.paris",
    src: Socotec
  },
  {
    href: "https://vinci-construction.com/fr/",
    id: "reference.esitc.paris",
    src: VinciConstruction
  },
  {
    href: "https://www.alpes-controles.fr/",
    id: "reference.esitc.paris",
    src: AlpesControles
  },
  {
    href: "https://pbm.fr/",
    id: "reference.esitc.paris",
    src: PBM
  },
  {
    href: "https://www.reseau-def.com/",
    id: "reference.esitc.paris",
    src: DEF
  },
  {
    href: "https://www.orange.fr/portail",
    id: "reference.esitc.paris",
    src: Orange
  },
  {
    href: "https://www.spie.com/fr",
    id: "reference.esitc.paris",
    src: Spie
  },
  {
    href: "https://www.esitc-paris.fr/fr",
    id: "reference.esitc.paris",
    src: ESITCParisPng
  },
  // {
  //   href: "http://batisseursdavenir.fr/",
  //   id: "reference.batisseurdavenir",
  //   src: BatisseurDAvenirPng
  // },
  {
    href: "https://www.vivier-bois-massifcentral.fr/",
    id: "reference.esitc.metz",
    src: VBMC
  },
  // {
  //   href: "https://www.ouvrages-olympiques.fr/",
  //   id: "reference.solideo",
  //   src: SolideoJpg
  // },
  {
    href: "https://www.datbim.com/",
    id: "reference.datbim",
    src: datBIMJpg
  },
  {
    href: "https://alliance-batiment.org/",
    id: "reference.datbim",
    src: AllianceDuBatiment
  },
  // {
  //   href: "https://impulse-partners.com/",
  //   id: "reference.impulsepartners",
  //   src: ImpulsePartnersPng
  // },
  {
    href: "https://www.buildingsmartfrance-mediaconstruct.fr/",
    id: "reference.buildingsmart",
    src: BuildingSmartPng
  },
  // {
  //   href: "https://www.hdsi.asso.fr/",
  //   id: "reference.buildingsmart",
  //   src: HDSIJpg
  // },
];

const ReferenceSection = ({
  link
}) => {
  const classes = useStyles();
  return (
    <div className="referenceSection">
      {/* <img className="imgWave" src={WaveSvg} alt="Wave" /> */}
      <Container className="referenceContainer">
        <h2 className="title">
          <FormattedMessage id="reference.title" defaultMessage="They trust us: " />
        </h2>
        <br />
        <div className={classes.root}>
          <Grid container spacing={3}>
            {referenceList.map((reference, index) => (
              <Grid item xs={3}>
                <Avatar className={classes.avatar} key={index}>
                  <a href={reference.href} target="_blank">
                    <FormattedMessage id={reference.id}>
                      {
                        alt => (
                          <img
                            className={classes.img}
                            src={reference.src}
                            alt={alt}
                          />
                        )
                      }
                    </FormattedMessage>
                  </a>
                </Avatar>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default ReferenceSection;
