import React, { Component } from 'react';
import Header from './scenes/Header';
import FonctionalitySection from '../LandingPages/Presentation/scenes/FonctionalitySection';
import AppSection from './scenes/AppSection';
import VideoSection from './scenes/VideoSection';
import IASection from './scenes/IASection';
import MobileSection from './scenes/MobileSection';
import NoInstallationSection from './scenes/NoInstallationSection';
import PowerfulSection from './scenes/PowerfulSection';
import GuarantySection from './scenes/GuarantySection';
import ReferenceSection from './scenes/ReferenceSection';
import Prospects from './scenes/Prospects';

const {
  REACT_APP_URL
} = process.env;

const Home = ({
  locale
}) => {
  const linkToApp = "https://app.tridyme.com";
  return (
    <div className="home">
      <Header link={linkToApp} />
      <ReferenceSection link={linkToApp} />
      <FonctionalitySection link={linkToApp} />
      {locale === "fr" &&
        <Prospects link={linkToApp} />
      }
      {/* <VideoSection link={linkToApp} /> */}
      {/* <AppSection link={linkToApp}/> */}
      <NoInstallationSection link={linkToApp} />
      <PowerfulSection link={linkToApp} />
      <GuarantySection link={linkToApp} />
      {/* <IASection />
      <MobileSection /> */}
    </div>
  );
};

export default Home;